.table-bordered {
    border: var(--gyg-border-width) solid var(--gyg-border-color);
    --gyg-table-color: var(--gyg-body-color);
    --gyg-table-bg: var(--gyg-secondary-bg);
    --gyg-table-striped-color: var(--gyg-body-color);
    --gyg-table-striped-bg: rgba(0, 0, 0, 0.05);
    --gyg-table-active-color: var(--gyg-body-color);
    --gyg-table-active-bg: rgba(0, 0, 0, 0.1);
    --gyg-table-hover-color: var(--gyg-body-color);
    --gyg-table-hover-bg: rgba(0, 0, 0, 0.075);
}
.table .table-light {
    background-color: var(--gyg-tertiary-bg) !important;
    border-color: var(--gyg-border-color);
    color: var(--gyg-body-color);
    --gyg-table-border-color: #dfe0e1;
}
.table > :not(caption) > * > * {
    padding: 0.75rem;
}

.table > tbody > tr > td {
    padding: 0.85rem 0.75rem 0.55rem 0.75rem;
    line-height: 1.1;
}

.active > .page-link {
    background-color: var(--gyg-color-primary);
    border-color: var(--gyg-color-primary);
    cursor: initial;
}

.table-responsive {
    min-height: 400px;
}

.table-kardex {
    font-size: 0.7rem;
    thead {
        th {
            text-align: center;
            vertical-align: middle;
            background-color: $color-primary;
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
                vertical-align: middle;
            }
        }
        .body-product-kardex td{
            font-weight: bold;
            background-color: lighten($color-primary, 40%);
        }
        .footer-kardex{
            font-weight: bold;
            font-style: italic;
        }
    }
}
