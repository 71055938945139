.loader-app {
  background-color: #fff;
  .loading {
    width: 175px;
    height: 80px;
    display: block;
    margin: auto;
    background-image: radial-gradient(circle 25px at 25px 25px, $color-primary 100%, transparent 0),
      radial-gradient(circle 50px at 50px 50px, $color-primary 100%, transparent 0),
      radial-gradient(circle 25px at 25px 25px, $color-primary 100%, transparent 0),
      linear-gradient($color-primary 50px, transparent 0);
    background-size:
      50px 50px,
      100px 76px,
      50px 50px,
      120px 40px;
    background-position:
      0px 30px,
      37px 0px,
      122px 30px,
      25px 40px;
    background-repeat: no-repeat;
    position: relative;
    box-sizing: border-box;
  }
  .loading::after {
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20px;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-color: #fff transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

.loader-two {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: $color-primary;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-state {
  background: rgba(0, 0, 0, 0.5098039216);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2000;
  color: #fff;
  .state-loading{
    text-align: center;
    height: 60px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 18px;
  }
  .title{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}
