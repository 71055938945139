.btn {
    --gyg-btn-padding-x: 0.75rem;
    --gyg-btn-padding-y: 0.47rem;
    --gyg-btn-font-size: 0.9rem;
}
.btn-group-lg>.btn, .btn-lg {
    --gyg-btn-padding-y: .5rem;
    --gyg-btn-padding-x: 1rem;
    --gyg-btn-font-size: 1.125rem;
    --gyg-btn-border-radius: var(--gyg-border-radius-lg);
}
.btn-group-sm > .btn,
.btn-sm {
    --gyg-btn-padding-y: 0.25rem;
    --gyg-btn-padding-x: 0.5rem;
    --gyg-btn-font-size: 0.7875rem;
}

.btn-xs {
    --gyg-btn-padding-y: 0.15rem;
    --gyg-btn-padding-x: 0.3rem;
    --gyg-btn-font-size: 0.6875rem;
}

.btn-primary {
    --gyg-btn-color: var(--gyg-white);
    --gyg-btn-bg: var(--gyg-color-primary);
    --gyg-btn-border-color: var(--gyg-color-primary);
    --gyg-btn-hover-color: var(--gyg-white);
    --gyg-btn-hover-bg: #{shade-color($color-primary, 10%)};
    --gyg-btn-hover-border-color: #{shade-color($color-primary, 10%)};
    --gyg-btn-focus-shadow-rgb: var(--gyg-color-primary-rgb);
    --gyg-btn-active-color: var(--gyg-btn-hover-color);
    --gyg-btn-active-bg: #{shade-color($color-primary, 20%)};
    --gyg-btn-active-border-color: #{shade-color($color-primary, 20%)};
    --gyg-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --gyg-btn-disabled-bg: var(--gyg-color-primary);
    --gyg-btn-disabled-border-color: var(--gyg-color-primary);
}
.btn-group{
    width: 100%;
}
.btn-group>input:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menu {
    --gyg-dropdown-zindex: 1000;
    --gyg-dropdown-min-width: 10rem;
    --gyg-dropdown-padding-x: 0;
    --gyg-dropdown-padding-y: .5rem;
    --gyg-dropdown-spacer: .125rem;
    --gyg-dropdown-font-size: .9rem;
    --gyg-dropdown-color: var(--gyg-body-color);
    --gyg-dropdown-bg: var(--gyg-secondary-bg);
    --gyg-dropdown-border-color: var(--gyg-border-color);
    --gyg-dropdown-border-radius: var(--gyg-border-radius);
    --gyg-dropdown-border-width: var(--gyg-border-width);
    --gyg-dropdown-inner-border-radius: calc(var(--gyg-border-radius) - var(--gyg-border-width));
    --gyg-dropdown-divider-bg: var(--gyg-border-color);
    --gyg-dropdown-divider-margin-y: .5rem;
    --gyg-dropdown-box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175);
    --gyg-dropdown-link-color: var(--gyg-body-color);
    --gyg-dropdown-link-hover-color: var(--gyg-body-color);
    --gyg-dropdown-link-hover-bg: var(--gyg-tertiary-bg);
    --gyg-dropdown-link-active-color: var(--gyg-secondary-color);
    --gyg-dropdown-link-active-bg: var(--gyg-tertiary-bg);
    --gyg-dropdown-link-disabled-color: var(--gyg-tertiary-color);
    --gyg-dropdown-item-padding-x: 1.5rem;
    --gyg-dropdown-item-padding-y: .35rem;
    --gyg-dropdown-header-color: #74788d;
    --gyg-dropdown-header-padding-x: 1.5rem;
    --gyg-dropdown-header-padding-y: .5rem;
    background-clip: padding-box;
    background-color: var(--gyg-dropdown-bg);
    border: var(--gyg-dropdown-border-width) solid var(--gyg-dropdown-border-color);
    border-radius: var(--gyg-dropdown-border-radius);
    color: var(--gyg-dropdown-color);
    display: none;
    font-size: var(--gyg-dropdown-font-size);
    list-style: none;
    min-width: var(--gyg-dropdown-min-width);
    padding: var(--gyg-dropdown-padding-y) var(--gyg-dropdown-padding-x);
    text-align: left;
    z-index: var(--gyg-dropdown-zindex);
}

.dropdown-menu{
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: DropDownSlide;
    box-shadow: 0 5px 12px #0000001a;
    margin: 0;
}

.btn-block {
    display: block;
    width: 100%;
}

@keyframes DropDownSlide {
    from {
        transform: translate3d(0, 20px, 0);
    }
    to {
        transform: translate3d(0, 24.5px, 0);
    }
}