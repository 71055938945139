.sidebar {
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 235px;
    height: 100%;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px RGB(0 0 0 / 8%);
    background-color: #fff;
}

.sidebar-header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 3.5rem;
    padding: 0 8px;
    box-shadow: 0 2px 4px RGB(0 0 0 / 8%);
}
.sidebar-body,
.sidebar-header {
    width: 235px;
}

.logo-auth {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.1;
    display: flex;
    align-items: center;
}
.logo-auth img {
    width: 40px;
    height: 33px;
    margin-right: 0.5rem;
}

.nav-link {
    color: #4a5568;
}
.nav-link.nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
}
.sidebar-header [data-toggle="sidebar"] {
    display: none;
}

.sidebar-body {
    position: relative;
    overflow-y: auto;
    height: calc(100% - 3.5rem);
}

.nav.treeview {
    flex-direction: column;
}
.nav-label {
    padding: 12px 20px !important;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: #505d69;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    opacity: 0.5;
}
.metismenu {
    margin: 0;
}
#sidebar-menu ul li a {
    display: block;
    padding: 0.625rem 1rem;
    color: var(--gyg-sidebar-menu-item-color);
    position: relative;
    font-size: 0.831rem;
    transition: all 0.4s;
    font-weight: 600;
}
#sidebar-menu ul li a i {
    display: inline-block;
    min-width: 1.5rem;
    padding-bottom: 0.125em;
    font-size: 1.4rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: var(--gyg-sidebar-menu-item-color);
    transition: all 0.4s;
    opacity: 0.75;
    margin-right: 0.3rem;
    font-weight: 500;
}
#sidebar-menu ul li a:hover,
#sidebar-menu ul li a:hover i {
    color: var(--gyg-sidebar-menu-item-hover-color);
}

.nav-link.active {
    color: $color-primary !important;
}
.nav-link.active i {
    color: $color-primary !important;
}
.nav-link.has-icon {
    display: flex;
    align-items: center;
}
.sidebar .treeview a.active,
.sidebar .treeview a:hover {
    font-weight: 500;
}

.nav.treeview svg {
    width: 24px;
}

.simplebar-track.simplebar-vertical {
    width: 7px !important;
}
.simplebar-track.simplebar-horizontal {
    height: 7px !important;
}

.treeview-toggle {
    cursor: pointer;
    white-space: nowrap;
}
.nav.treeview .treeview-toggle {
    display: flex;
    align-items: center;
}
.nav.treeview i {
    margin-right: 1rem;
}

#sidebar-menu .has-arrow.collapsed:after {
    transform: rotate(0deg);
}

#sidebar-menu .has-arrow:after {
    content: "\ea4e";
    font-family: "remixicon" !important;
    display: block;
    float: right;
    transition: transform 0.2s;
    font-size: 1rem;
    transform: rotate(180deg);
}

.nav.treeview .nav {
    flex-direction: column;
}
.nav.treeview .treeview-toggle.has-icon + ul {
    padding-left: calc(1rem + 1.5em);
}
.nav.treeview:not(.treeview-folder) ul a {
    font-size: 0.9375rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.router-link-exact-active .nav-link {
    color: $color-primary !important;
}
.router-link-exact-active .nav-link i {
    color: $color-primary !important;
}
#sidebar-menu ul li ul.sub-menu li {
    width: 100%;
}
#sidebar-menu ul li ul.sub-menu li a {
    padding: 0.4rem 1.5rem 0.4rem 3.2rem;
    font-size: 13px;
    color: #7c8a96;
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: 0 0;
        border: 1px solid #7b8190;
        position: absolute;
        left: 25px;
        top: 14px;
        transition: all 0.4s ease-in-out;
    }
}

#sidebar-menu ul.sub-menu li a.active {
    color: var(--gyg-sidebar-menu-item-hover-color) !important;
    &:before {
        background: $color-primary;
        border-color: $color-primary;
    }
}

@media (max-width: 991.98px) {
    .sidebar-expand .sidebar-header [data-toggle="sidebar"] {
        display: flex;
    }
    .sidebar {
        transform: translate3d(-235px, 0, 0);
    }
    .sidebar-expand .sidebar {
        transform: translate3d(0, 0, 0);
        border-right: 0 !important;
    }
    .sidebar-backdrop {
        position: fixed;
        z-index: 1030;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .sidebar-expand .sidebar-backdrop {
        display: block;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }
}

@media (min-width: 992px) {
    .sidebar-collapse .sidebar {
        width: 3.5rem;
    }
    .sidebar-collapse .sidebar .nav-label {
        display: none;
    }
    .sidebar-collapse .sidebar:hover {
        width: 235px;
    }
    .sidebar-collapse .sidebar:hover .nav-label {
        display: block;
    }
    .sidebar-collapse .sidebar .collapse.show {
        display: none;
        height: 0;
    }
    .sidebar-collapse .sidebar:hover .collapse.show {
        display: block;
        height: auto;
    }
    .sidebar-collapse .main {
        margin-left: 3.5rem;
    }
}
