.main-header {
  position: sticky;
  z-index: 1030;
  top: 0;
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px RGB(0 0 0 / 8%);

  .separator {
    background-image: linear-gradient(#f6f6f6 60%, #ededed);
    display: inline-block;
    height: 100%;
    margin: 0 25px;
    width: 1px;
    vertical-align: middle;
  }

  .userbox {
    display: inline-block;
    margin: 3px 17px 0 0;
    position: relative;
    vertical-align: middle;

    > a {
      display: inline-block;
      text-decoration: none;
    }

    .profile-info,
    .profile-picture {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }

    .profile-picture img {
      width: 35px;
      color: transparent;
    }

    .profile-info {
      margin: -3px 25px 0 10px;

      .name,
      .role {
        display: block;
      }

      .name {
        color: #001;
        font-size: 0.8rem;
        line-height: 1.1rem;
      }

      .role {
        color: #acacac;
        font-size: 0.7rem;
        line-height: 0.7rem;
      }
    }

    .custom-caret {
      color: #001;
      font-size: 16px;
      font-weight: 700;

      &:before {
        content: "\f107";
      }
    }
  }
  .userbox a {
    position: relative;
    z-index: 993;
  }

  .dropdown-menu.show {
    border: none;
    box-shadow: 0 1px 2px 1px RGB(0 0 0 / 20%);
    box-sizing: content-box;
    left: -11px !important;
    padding: 45px 10px 0;
    top: -10px !important;
    transform: none !important;
    width: 100%;
    min-width: 0;
    z-index: 992;
    background: #fff;

    .divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    li {
      margin-bottom: 5px;
    }

    a {
      border-radius: 2px;
      color: #7d7d7d;
      display: block;
      line-height: 1.3rem;
      padding: 4px 10px;
      font-size: 0.85rem;
      cursor: pointer;

      i {
        margin-right: 3px;
        vertical-align: middle;
        font-size: 1.1rem;
      }

      &:hover {
        background: $color-primary;
        color: #fff;
      }
    }
  }
}

.inner-wrapper {
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 3.5rem);
  transition: transform 0.3s;

  .inner-main,
  .inner-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .inner-main {
    right: 0;
    left: 0;

    &-footer,
    &-header {
      height: 3.5rem;
      box-shadow: 0 2px 4px RGB(0 0 0 / 8%);
      display: flex;
      align-items: center;
      padding: 0 1rem;
      flex-shrink: 0;
    }

    &-footer,
    &-header {
      background-color: #fff;
    }

    &-header h5 {
      margin-bottom: 0;
    }

    &-body {
      padding: 1rem;
      overflow-y: auto;
      position: relative;
      flex: 1 1 auto;
    }
  }

  .inner-sidebar-body {
    padding: 1rem;
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
  }
}

@media (min-width: 992px) {
  .main {
    margin-left: 235px;
  }
}

@media (max-width: 576px) {
  .userbox .profile-info {
    margin: -3px 0px 0 10px;

    span {
      display: none;
    }

    .custom-caret {
      vertical-align: middle;
    }
  }
}

section.body.sidebar-collapse {
  .ml-1 {
    margin-left: 0 !important;
  }
}

section.body.sidebar-expand {
  .ml-1 {
    margin-left: 0.7rem !important;
  }
}

.inner-main-header {
  .breadcrumbs {
    display: inline-block;
    font-size: 0;
    line-height: 50px;
    margin: 0;
    padding: 0;

    li {
      color: #c3c3c3;
      display: inline-block;
      font-weight: 300;
      font-family: "Inter", sans-serif;

      i {
        vertical-align: middle;
        font-size: 1.4rem;
      }
      a{
        cursor: pointer;
      }
      a:hover{
        color: #333;
      }

      a,
      span {
        color: #333;
        display: inline-block;
        font-size: 0.9rem;
        line-height: 20px;
        vertical-align: middle;
      }

      &:after {
        content: "/";
        display: inline-block;
        font-size: 0.85rem;
        margin: 0 10px;
        vertical-align: middle;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}

.page-header {
  .sidebar-right-toggle {
    cursor: pointer;
    color: #c3c3c3;
    display: inline-block;
    font-size: 17px;
    margin: 0 0 0 10px;
    height: 50px;
    width: 50px;
    vertical-align: top;
    text-align: center;
    transition: all 0.15s ease-in-out;
  }
}

@media only screen and (min-width: 768px) {
  .page-header.sidebar-expand {
    .sidebar-right-toggle {
      margin-left: 0.7rem;
    }
  }
}

@media only screen and (min-width: 991.98px) {
  .page-header {
    left: 236px;
    margin: 0;
    position: fixed;
    right: 0;
    top: 60px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media only screen and (max-width: 991.98px) {
  .inner-main-header {
    .breadcrumbs {
      display: none;
    }
  }
  .main-body {
    padding-top: 0;
  }
}
