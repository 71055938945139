.css-13cymwt-control, .css-t3ipsp-control, .css-16xfy0z-control{
    border: 1px solid var(--gyg-border-color-translucent) !important;
    border-radius: var(--gyg-border-radius) !important;
    min-height: 29.7px !important;
}
.css-1fdsijx-ValueContainer, .css-hlgwow{
    padding: 0.275rem 2.25rem 0.275rem 0.75rem !important;
    font-size: .7875rem !important;
}
.css-t3ipsp-control{
    border-color: lighten($color-primary, 21) !important;
    box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.25) !important;
}
.css-1jqq78o-placeholder{
    padding: 0 !important;
    margin: 0 !important;
    color: var(--gyg-body-color) !important;
    line-height: 10px !important;
}
.css-qbdosj-Input, .css-1h01tm3-Input, .css-19bb58m, .css-1mkvw8y, .css-1cfo1cf {
    padding: 0 !important;
    margin: 0 !important;
}
.css-166bipr-Input{
    padding: 0 !important;
    margin: 0 !important;
}
.css-1u9des2-indicatorSeparator, .css-15lsz6c-indicatorContainer{
    margin: 0 !important;
    padding: 0 !important;
    background-color: #fff !important;
}
.css-15lsz6c-indicatorContainer{
    padding-right: 0.5rem !important;
}
.css-1xc3v61-indicatorContainer{
    padding: 0rem 0.5rem 0rem 0rem !important;
}
.css-d7l1ni-option{
    background-color: lighten($color-primary, 45) !important;
}
.css-tr4s17-option{
    background-color: $color-primary !important;

}
.css-tj5bde-Svg{
    color: transparent !important;
    background-image: url('../../assets/svg/select.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 12px;
}