.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    outline: 0;
}
.modal-content {
    box-shadow:
        0 5px 5px -3px RGB(0 0 0 / 20%),
        0 8px 10px 1px RGB(0 0 0 / 14%),
        0 3px 14px 2px RGB(0 0 0 / 12%);
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0 solid #cbd5e0;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}
.modal-header {
    box-shadow:
        0 1px 3px 0 RGB(0 0 0 / 10%),
        0 1px 2px 0 RGB(0 0 0 / 6%);
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 0 solid #cbd5e0;
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
}
.modal-footer {
    box-shadow:
        0 -1px 3px 0 RGB(0 0 0 / 10%),
        0 -1px 2px 0 RGB(0 0 0 / 6%);
}
.border-top {
    border-top: 1px solid #cbd5e0 !important;
}

.h-modal-body {
    height: calc(var(--app-content-height) - 17px) !important;
}
.form-scrollable {
    max-height: calc(100vh - 7rem);
    display: flex;
    flex-direction: column;
}
.modal-body-scrollable {
    height: calc(100vh - 7rem);
}
.btn-close:focus {
    box-shadow: none;
}
.modal-dialog-scrollable {
    height: calc(100% - 1.55rem * 2);
}
@media (min-width: 576px) {
    .modal {
        --gyg-modal-margin: 1.55rem;
    }
}
.form-group {
    margin-bottom: 1rem;
}

.h-10 {
    height: 10% !important;
}
.h-90 {
    height: 90% !important;
}
.h-80 {
    height: 80% !important;
}
.h-100 {
    height: 100% !important;
}
.w-100 {
    width: 100% !important;
}

@media (min-width: 576px) {
    .modal-gg {
        max-width: 810px;
    }
    .modal-gg-plus {
        max-width: 80%;
    }
}
